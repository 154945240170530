// Generated by Framer (16060ad)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {w71gzHeUQ: {hover: true}};

const cycleOrder = ["w71gzHeUQ"];

const variantClassNames = {w71gzHeUQ: "framer-v-1wie6l2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "w71gzHeUQ", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "w71gzHeUQ", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qZKq9", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1wie6l2", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"w71gzHeUQ"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"w71gzHeUQ-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-1bxppaf"} data-framer-name={"home_logo_css"} fill={"black"} intrinsicHeight={70} intrinsicWidth={160} layoutDependency={layoutDependency} layoutId={"KP7RFz35x"} style={{opacity: 0.5}} svg={"<svg data-name=\"Component 825 – 1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"160\" height=\"70\"><g fill=\"#666\"><path data-name=\"Path 3191\" d=\"M83.27 44.517c-2.23 0-4.49-1.46-6.07-2.51a8.539 8.539 0 0 0-4.86-1.7 9.017 9.017 0 0 0-5 1.74c-1.79 1.09-3.57 2.39-7 2.39-5.83 0-9.15-4-9.15-9.56a8.923 8.923 0 0 1 8.54-9.3c.15-.01.31-.01.46-.01a10.314 10.314 0 0 1 7.33 2.55l2.67-3.81a15 15 0 0 0-10-3.48 13.662 13.662 0 0 0-13.85 13.48v.57c0 8.34 5.71 14.3 14 14.3a14.557 14.557 0 0 0 7.79-2.35c1.33-.85 2.59-1.87 4-1.87s2.14.49 3.92 1.67 4.13 2.55 6.81 2.55a11.128 11.128 0 0 0 7.25-2.76l-2.64-3.4a6.4 6.4 0 0 1-4.2 1.5Z\"/><path data-name=\"Path 3192\" d=\"m106.88 33.337-3-1.25c-2.39-1-3.92-1.66-3.92-3.48s1.7-3.29 4.85-3.29a8.972 8.972 0 0 1 5.43 2l2.59-3.56a13.41 13.41 0 0 0-8.42-2.93c-5.22 0-9.15 3.4-9.15 8.1 0 3.81 3.16 5.87 6 7.13l1.78.77c3.93 1.7 5.71 2.34 5.71 4.45 0 1.78-1.66 3.28-4.86 3.28-3.64 0-6.23-2.19-8.5-4.94-3.16-3.64-5.31-5.43-10-6.76-5-1.38-6.4-2.31-6.4-4.25 0-1.79 1.7-3.29 4.86-3.29a9.007 9.007 0 0 1 5.42 2l2.6-3.56a13.465 13.465 0 0 0-8.44-2.93c-5.22 0-9.15 3.4-9.15 8.1 0 5.34 4.9 7.05 9 8.22s6.12 2.35 9 6.24a14.194 14.194 0 0 0 11.54 5.79c5.83 0 9.84-3 9.84-8-.06-2.86-1.52-5.61-6.78-7.84Z\"/></g></svg>"} transition={transition} variants={{"w71gzHeUQ-hover": {opacity: 1}}} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qZKq9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qZKq9 .framer-cgelrr { display: block; }", ".framer-qZKq9 .framer-1wie6l2 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 160px; }", ".framer-qZKq9 .framer-1bxppaf { aspect-ratio: 2.2857142857142856 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 70px); position: relative; width: 100%; }", ".framer-qZKq9 .framer-v-1wie6l2 .framer-1wie6l2 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qZKq9 .framer-1wie6l2 { gap: 0px; } .framer-qZKq9 .framer-1wie6l2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-qZKq9 .framer-1wie6l2 > :first-child { margin-left: 0px; } .framer-qZKq9 .framer-1wie6l2 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"tw60HIy6R":{"layout":["fixed","auto"]}}}
 */
const FramerLb9ezJiUQ: React.ComponentType<Props> = withCSS(Component, css, "framer-qZKq9") as typeof Component;
export default FramerLb9ezJiUQ;

FramerLb9ezJiUQ.displayName = "logo-css";

FramerLb9ezJiUQ.defaultProps = {height: 70, width: 160};

addFonts(FramerLb9ezJiUQ, [])